import React from 'react';
import SpeedTest from "./SpeedTest";
import GoogleAnalytics from "../speed-troubleshooting/ga/GoogleAnalytics";
import BaseRunTimeContainer from "../BaseRunTimeContainer";

export default  class App extends BaseRunTimeContainer{
    constructor(props) {
        super(props, ["GlobalFooter", "Wrapper", "Section"]);
    }

    render(){
        const {GlobalFooter, Wrapper, Section} = this.runTimeComponents;
        return (
            <Section>
                <Wrapper>
                    <SpeedTest googleAnalytics={new GoogleAnalytics({pagePath:"/"})}/>
                </Wrapper>
                <GlobalFooter/>
            </Section>
        )
    }
}
