const usabillaScriptId = (context) => {
    switch(context){
        case "SPEED_TEST" : return window.metadata.app.usabilla.speedTestUsabillaScriptId || '6fb30bc3cb3d';
        default : return window.metadata.app.usabilla.supportTestUsabillaScriptId || '0766a0284702';
    }

};

const Usabilla = (context) => {
    window.usabilla.load("w.usabilla.com", usabillaScriptId(context));
};

export default Usabilla;