import React from 'react';
import $ from 'jquery';
import ooklaSpeedTestHandler from '../ooklaSpeedTestHandler';
import {AlertBarCustomized, ShortMsg} from 'assurance-common-components';

import UsabillaWidget from "../speed-troubleshooting/include/UsabillaWidget";
import SpeedInfoBoxTipsAndTricks from "./SpeedInfoBoxTipsAndTricks";
import "./SpeedTestMain.css";
import appAnalytics from "../utils/AppAnalytics";
import BaseRunTimeContainer from "../BaseRunTimeContainer";
import PersonalisedSpeedCheck from "./PersonalisedSpeedCheck";
import downloadIcon from "../images/singular-download-blue.svg";
import uploadIcon from "../images/singular-upload-blue.svg";
import tickIcon from "../images/control-ui-tick.svg"
import speedMeasure from "../images/speed-measure.svg"

export const SPEEDTEST_URL = window.metadata.app.speedTestUrl || "https://telstra-nbn.speedtestcustom.com";
export const TELSTRA_FIX_URL = window.metadata.app.smartTroubleshootingUrl || 'https://fix.telstra.com/login';

class SpeedTest extends BaseRunTimeContainer {
    constructor(props) {
        super(props, ["Section"]);
        this.state = {results: null};
        props.googleAnalytics.initialize();
    }

    calculateNetSpeedForAnalytics = (speed) => {
        if (speed) {
            return (parseInt((speed / 1000) * 10, 10) / 10);
        }
        return 0;
    };

    redirectToSmartTroubleshootingTool = (_props) => {
        _props.googleAnalytics.sendEvent("speedtest_troubleshoot");
        let _path = [];
        _path.push("redirect");
        _path.push("smarttroubleshoot");
        appAnalytics.send({
            eventType: "link",
            authenticated: "NotNeeded",
            path: _path,
            eventId: ["events"]
        });
        window.location = TELSTRA_FIX_URL;
    };

    sendAnalytics(data) {
        if (data) {
            var _path = [];
            _path.push("speedtest");
            _path.push("result");
            appAnalytics.send({
                eventType: "link",
                authenticated: false,
                path: _path,
                eventId: ["eVar54"],
                data: {
                    speedTestResult: {
                        downloadSpeed: ("Down: " + this.calculateNetSpeedForAnalytics(data.download)),
                        uploadSpeed: ("Up: " + this.calculateNetSpeedForAnalytics(data.upload)),
                        latency: ("Ping: " + data.latency.minimum),
                        unit: ("Mbps")
                    }
                }
            });
        }
    }

    componentDidMount() {
        ooklaSpeedTestHandler(this.ooklaSpeedTestHandler.bind(this));
    }

    componentDidUpdate() {
        /* istanbul ignore next */
        $(".option-label").parent().on("click", function () {
            let outerObject = this;

            $(".selected").each(function () {
                if ($(outerObject).text() !== $(this).text()) {
                    $(this).click();
                }
            });
        });
    }

    ooklaSpeedTestHandler(event) {
        if (event.origin !== SPEEDTEST_URL) {
            return;
        }
        this.sendAnalytics(event.data);
        this.setState({results: event.data});
        this.props.googleAnalytics.pageview("/results");
    }

    ooklaSpeedTest = () => {
        const {Section} = this.runTimeComponents;
        return (
            <Section className="speed-test-container-main">
                <div className="speed-test-container">
                    <h1 className="rt-heading-l rt-margin-top-spacing1x">Telstra Speed Test</h1>
                    <p className="rt-body-m-light rt-margin-top-spacing3x">Measure your
                        connection speed for your Telstra home broadband or mobile data service.</p>
                    <div className="grid-container hide-section">
                        <div className="grid-x">
                            <AlertBarCustomized type='warning'
                                                titleText='Telstra speed test is currently experiencing difficulties.'>
                                <ShortMsg>Please use <b><a href='https://www.speedtest.net/'
                                                           target="_blank">speedtest.net</a></b> in the meantime and
                                    ensure you select a <i>Telstra test server location</i> for more accurate
                                    results</ShortMsg>
                            </AlertBarCustomized>
                        </div>
                    </div>
                    <div className={"un-hide-section rt-margin-top-spacing5x rt-margin-bottom-spacing7x"}>
                        <iframe id="iframe" className="ookla-speed-test" frameBorder="0" scrolling="no"
                                src="//telstra-nbn.speedtestcustom.com"
                                title="Test your connection speed tool"/>
                        <div className="powered_by_ookla rt-body-xs-light">Powered by <b><a
                            href="https://www.ookla.com/" target="_blank"
                            className='speed-ookla-link rt-body-xs-light'>Ookla</a></b></div>
                    </div>
                </div>
                <div className={"rt-margin-clean rt-horizontal-divider"}></div>
                <PersonalisedSpeedCheck/>
                <div className={"rt-margin-clean rt-margin-top-spacing5x rt-horizontal-divider"}></div>
                <SpeedInfoBoxTipsAndTricks/>
                <div className={"rt-margin-clean rt-margin-top-spacing7x rt-horizontal-divider"}></div>
                {/*<SpeedTestEndPageTips/>*/}
                <div className={"rt-margin-top-spacing7x rt-margin-bottom-spacing10x"}>
                    <p className={"rt-body-xs-light"}>Telstra provides this test to help customers measure their
                        connection speed.
                        There are many different factors that impact the speeds you receive including the technology
                        type to your home or business, the speed tier of your plan, network capacity and the internet set-up you are using.</p>

                </div>
            </Section>
        );
    };

    close = (event, videoType) => {
        /* istanbul ignore next */
        if (event) {
            event.preventDefault();
            $('#speed-result-info-icon-' + videoType).removeClass("fas");
            $('#speed-result-info-icon-' + videoType).addClass("fal");
            $('#speed-test-tooltip-' + videoType).removeClass("tooltip-active");
            $('#speed-test-tooltip-' + videoType).addClass("tooltip-inactive");
        }
    };

    open = (event, videoType) => {
        /* istanbul ignore next */
        if (event) {
            event.preventDefault();
            $('#speed-test-tooltip-' + videoType).removeClass("tooltip-inactive");
            $('#speed-test-tooltip-' + videoType).addClass("tooltip-active");
            $('#speed-result-info-icon-' + videoType).removeClass("fal");
            $('#speed-result-info-icon-' + videoType).addClass("fas");
        }
    };

    speedCheck(activityType, downloadSpeed, uploadSpeed) {
        const success = {type:"success", text:"Fast"};
        const inProgress = {type:"in-progress", text:"Okay"};
        const negative = {type:"negative", text:"Slow"};
        if(activityType == "browseInternet"){
            if( downloadSpeed >= 3){
                return success;
            }else if(downloadSpeed >=1 && downloadSpeed < 3){
                return inProgress;
            }else {
                return negative;
            }
        }else if (activityType == "streamVideos"){
            if( downloadSpeed >=7){
                return success;
            }else if(downloadSpeed >= 3 && downloadSpeed < 7){
                return inProgress;
            }else {
                return negative;
            }
        }else{
            if( downloadSpeed >= 2 && uploadSpeed >= 2){
                return success;
            }else if(downloadSpeed >= 1 && uploadSpeed >= 1 && downloadSpeed<=2 && uploadSpeed <=2){
                return inProgress;
            }else {
                return negative;
            }

        }
    }
    getSpeed(pingSpeed){
       return Math.floor(pingSpeed)+"ms";
    }


    ooklaSpeedTestResults = () => {
        const pingSpeed = this.state.results.latency.minimum;
        let download=parseInt((this.state.results.download / 1000) * 10, 10) / 10;
        let upload=parseInt((this.state.results.upload / 1000) * 10, 10) / 10;
        let uploadUnit = "Mbps";
        let downloadUnit = "Mbps";

        if(download >= 1000){
            download = parseInt((download / 1000) * 10, 10) / 10;
            downloadUnit = "Gbps"
        }
        if(upload >= 1000){
            upload = parseInt((upload / 1000) * 10, 10) / 10;
            uploadUnit = "Gbps"
        }

        const browseInternet = this.speedCheck("browseInternet", download, upload);
        const streamVideos = this.speedCheck("streamVideos", download,upload);
        const videoCalling = this.speedCheck("videoCalling", download,upload);
        const onlineGamingSpeed = this.getSpeed( pingSpeed );

        const {Section, Lozenge, ShowHide, Button} = this.runTimeComponents;
        const button = <h2 className="info-to-know rt-heading-xs">Understand your test results</h2>
        const button2 = <h2 className="info-to-know rt-heading-xs">Factors that may impact speed</h2>
        const resultsContent = <div className="info-to-know">
            <h1 className={"rt-heading-s rt-margin-top-spacing7x"}>Results breakdown</h1>
            <p className={"rt-body-s rt-margin-bottom-spacing3x rt-margin-top-spacing3x"}>Based on these test results,
                you
                should be able to do the following activities during non peak periods:</p>

            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}
                 className={"rt-margin-top-xxs"}>
                <h3 className={"rt-heading-xs"}>Browse the internet</h3>
                <Lozenge className={"lozenge-override-styles"} type={browseInternet.type} text={browseInternet.text}/>
            </div>
            <p className="rt-body-s">Things like social media, news or email.</p>
            <div className={"rt-margin-clean rt-margin-top-spacing2x rt-horizontal-divider"}></div>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}
                 className={"rt-margin-top-xxs"}>
                <h3 className={"rt-heading-xs"}>Stream videos</h3>
                <Lozenge className={"lozenge-override-styles"} type={streamVideos.type} text={streamVideos.text}/>
            </div>
            <p className="rt-body-s">Things like You Tube, Netflix or Foxtel Now.</p>
            <div className={"rt-margin-clean rt-margin-top-spacing2x rt-horizontal-divider"}></div>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}
                 className={"rt-margin-top-xxs"}>
                <h3 className={"rt-heading-xs"}>Video calling</h3>
                <Lozenge className={"lozenge-override-styles"} type={videoCalling.type} text={videoCalling.text}/>
            </div>
            <p className="rt-body-s">Things like Skype, Face Time or HD video.</p>
            <div className={"rt-margin-clean rt-margin-top-spacing2x rt-horizontal-divider"}></div>
            <h1 className={"rt-margin-clean rt-margin-top-xxs rt-margin-bottom-spacing2x rt-heading-s"}>Other results</h1>
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}
                 className={"rt-margin-top-xxs"}>
                <h3 className={"rt-heading-xs"}>Gaming ping</h3>
                <Lozenge className={"lozenge-override-styles"} type="in-progress" text={onlineGamingSpeed}/>
            </div>
            <p className="rt-body-s">Latency and responsiveness of play (ping).</p>
            <p className="rt-body-s rt-margin-bottom-spacing7x">Varies depending on the geographical location of the service you're using.</p>
            <div className={"rt-margin-clean rt-margin-top-spacing2x rt-horizontal-divider"}></div>
        </div>
        const speedImpactContent = <div className="info-to-know">
            {/*multiple devices in use*/}
            <h3 className={"rt-heading-xs rt-margin-top-spacing4x"}>Multiple devices in use</h3>
            <p className={"rt-body-s rt-margin-top-spacing2x"}>If lots of devices are connected to your
                internet, your connection is shared between them.</p>
            <p className={"rt-body-s rt-margin-bottom-spacing3x"}>Try disconnecting the devices that you're not using to
                help free up your bandwidth.</p>
            {/*issue with your device*/}
            <h3 className={"rt-heading-xs rt-margin-top-spacing3x rt-margin-bottom-spacing2x"}>Issue with your
                device</h3>
            <p className={"rt-body-s"}>If your device has apps running in the background
                or it's an older model, it can affect your speed.</p>
            <p className={"rt-body-s rt-margin-bottom-spacing3x"}>Try running tests on your other devices to see if they
                achieve faster speeds. Close any apps you're not using or restart your device.</p>
            {/*Wi-Fi*/}
            <h3 className={"rt-heading-xs rt-margin-top-spacing3x rt-margin-bottom-spacing2x"}>Your Wi-Fi network</h3>
            <p className={"rt-body-s"}>A wireless connection can be affected by things like the location
                of your modem, your device's age or number of apps running.</p>
            <p className={"rt-body-s"}>Try restarting your modem, moving closer to it, or connecting
                to the internet using an ethernet cable.</p>
            <p className={"rt-body-s rt-margin-bottom-spacing3x"}>
                <a target="_blank" className={"bold-link"} href="https://www.telstra.com.au/internet/extras/get-wifi#coverage">More ways to improve Wi-Fi connection</a></p>

            {/* <p className="rt-body-s"></p>*/}
            <h3 className={"rt-heading-xs rt-margin-bottom-spacing2x"}>Time of the day</h3>
            <p className={"rt-body-s"}>Your connection speed can vary at peak hours when lots of people are online.
                This is typically between 7pm and 11pm.</p>
            <p className={"rt-body-s"}>Try doing some activity outside of peak hours, like sending large files during
                the day or overnight.</p>
            <p className={"rt-body-s rt-margin-bottom-spacing3x"}><a target="_blank" className={"bold-link"}
                                                                     href="https://www.telstra.com.au/internet/nbn/nbn-speeds-explained">Learn
                about typical speeds</a></p>
            {/*wiring or cabling*/}
            <h3 className={"rt-heading-xs rt-margin-bottom-spacing2x"}>Wiring or cabling</h3>
            <p className={"rt-body-s"}>If your connection seems slow on all your devices,
                there could be an issue with your cables.</p>

            <p className={"rt-body-s"}>Try checking the cables or restarting your modem, and replace any old or damage
                wiring.</p>
            {/*nbn network*/}
            <h2 className={"rt-heading-xs rt-margin-bottom-spacing2x rt-margin-top-spacing3x"}>nbn&trade; network</h2>
            <p className={"rt-body-s rt-margin-top-spacing2x rt-margin-bottom-spacing3x"}>For more information
                about what to expect when it comes to the nbn&trade;
                network and your internet speeds visit <a target="_blank" className={"bold-link"}
                                                          href="https://www.telstra.com.au/internet/nbn/nbn-speeds-explained">nbn&trade; speeds
                    explained.</a></p>

            {/*mobile network*/}
            <h3 className={"rt-heading-xs rt-margin-bottom-spacing2x rt-margin-top-spacing3x"}>Mobile Network</h3>
            <p className={"rt-body-s rt-margin-bottom-spacing4x"}>For more information
                about what to expect when it comes to the Telstra Mobile network and your
                internet speeds visit <a target="_blank" className={"bold-link"}
                                         href="https://www.telstra.com.au/coverage-networks/our-coverage">Coverage and Networks.</a></p>
        </div>

        return (
            <Section className="speed-test-result-main">
                <div style={{display: "flex"}} className={"rt-margin-top-spacing3x speed-test-box seed-test-run-again"}>
                    <img alt={"tick icon"} className={"rt-margin-left-spacing1x"} src={tickIcon}/>
                    <p className={"rt-body-xs-light rt-margin-left-xxs"}>Speed test
                        complete. <a href="/"><u>Run again</u></a></p>
                </div>
                <img src={speedMeasure}
                     className={"rt-margin-clean rt-margin-top-spacing1x rt-margin-bottom-spacing3x"}
                     style={{"height": "15rem"}}
                     alt={"Speed test measure"}/>
                <h1 className={"rt-heading-l rt-margin-bottom-spacing3x"}>Your speed test results</h1>
                <div className={"rt-margin-bottom-spacing7x"}>
                    <div className={"speed-details"}>
                        <div className={"rt-margin-right-spacing5x download-speed speed"}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <h1 className={"rt-heading-xl"}>{download}<sub
                                    className={"rt-body-l  rt-margin-left-xxs"}>{downloadUnit}</sub></h1>
                                <img alt="Time of the day" src={downloadIcon}/>
                            </div>
                            <h3 className={"rt-body-xs-dark"}>DOWNLOAD SPEED</h3>
                            <p className={"rt-body-s"}>Higher speeds give you a better experience for things like video
                                streaming or internet browsing.</p>
                        </div>
                        <div className={"rt-margin-right-spacing5x upload-speed speed"}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                <h1 className={"rt-heading-xl"}>{upload}<sub
                                    className={"rt-body-l rt-margin-left-xxs"}>{uploadUnit}</sub></h1>
                                <img alt="Time of the day" src={uploadIcon}/>
                            </div>
                            <h3 className={"rt-body-xs-dark"}>UPLOAD SPEED</h3>
                            <p className={"rt-body-s"}>Higher speeds help things like video calling, sharing large files
                                and online gaming.</p>
                        </div>
                    </div>
                </div>
                <ShowHide button={button} content={resultsContent} open={false}/>
                <ShowHide button={button2} content={speedImpactContent} open={false}/>
                <div className={"rt-margin-clean rt-horizontal-divider"}></div>



                <div className={""}>
                    <h1 className={"rt-margin-clean rt-margin-top-spacing7x rt-margin-bottom-spacing2x rt-heading-s"}>Are you having trouble with your internet?</h1>
                    <p className={"rt-margin-clean rt-margin-bottom-spacing3x rt-body-m-light"}>Run automated tests on
                        your service to diagnose and resolve your query.</p>
                    <Button variant={"HighEmphasis"} className={"rt-margin-clean rt-margin-bottom-spacing7x"}
                            aria-label="Click to get help now"
                            onClick={() => window.location = "https://open.mytelstra.app/BJRB/1b713192"}>Get help now</Button>
                </div>


                <div className={"rt-margin-clean rt-margin-top-spacing2x rt-horizontal-divider"}></div>
                <div className={"rt-margin-top-spacing7x rt-margin-bottom-spacing10x"}>
                    <p className={"rt-body-xs-light"}>Telstra provides this test to help customers measure their
                        connection speed.
                        There are many different factors that impact the speeds you receive including the technology
                        type to your home or business, the speed tier of your plan, network capacity and the internet set-up you are using.</p>

                </div>

                <UsabillaWidget context="SPEED_TEST"/>

            </Section>
        )
    };

    render() {
        const {Section} = this.runTimeComponents;
        return (
            <Section>
                {this.state.results ? this.ooklaSpeedTestResults() : this.ooklaSpeedTest()}
            </Section>

        );
    }
}

export default SpeedTest;
