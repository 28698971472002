import React from 'react';
import BaseRunTimeContainer from "../BaseRunTimeContainer";
import telstraSpeed from "../images/myTelstra-speed.svg";
import "./SpeedTestMain.css";

export default class PersonalisedSpeedCheck extends BaseRunTimeContainer{

    constructor(props) {
        super(props,["Section","Button"]);
    }

    render() {
        const {Button,Section} = this.runTimeComponents;
        return(
            <Section className={"rt-margin-top-spacing5x rt-margin-bottom-spacing5x speed-check"}>
                <div style={{display:"grid","grid-template-columns":"auto 1fr"}}>
                    <img className={"rt-margin-right-spacing2x img-grid-row"} alt="Speed Telstra" src={telstraSpeed}/>
                    <h3 className={"rt-heading-xs"}>Need a personalized breakdown of your speed performance?</h3>
                    <p className={"rt-body-xs-light rt-margin-top-spacing2x rt-margin-bottom-spacing2x"}>Download the my Telstra app and tap on the Get Help icon to get started.</p>
                    <Button variant="LowEmphasis" element="a" linkOpensNewTab={true}  href="https://www.telstra.com.au/mytelstra" className={"rt-body-xs-light"}>Go to The My Telstra app</Button>
                </div>
            </Section>
        )
    }

}
