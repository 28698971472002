
/* eslint-disable */
export class AppAnalytics{
	constructor() {
		this.analyticsStream = [];
		this.isCheckingOmniture = "not-loaded";
		try{
			if(localStorage.hasOwnProperty("analytics-stream")) {
				this.analyticsStream = JSON.parse(localStorage.getItem("analytics-stream"));
				localStorage.removeItem("analytics-stream");
			}
		}catch(e){
			localStorage.removeItem("analytics-stream");
		}
		this.processStream();
	}

	send(data){
		setTimeout(()=>{
			this.analyticsStream.push(data);
			this.processStream();
		},0);
	}

	processStream(){
		if(this.isCheckingOmniture === "not-loaded"){
			this.isCheckingOmniture = setInterval(()=>{
				if(window.s && window.s.track){
					clearTimeout(this.isCheckingOmniture);
					this.isCheckingOmniture = "loaded";
					localStorage.removeItem("analytics-stream");
					this.processStream();
				}
			},500);
		}else if(this.isCheckingOmniture === "loaded"){
			while(this.analyticsStream.length > 0){
				var _currentStreamData = this.analyticsStream.shift();
				if(this.getEventType(_currentStreamData).omniture === "link"){
					if(_currentStreamData.data){
						for(var _mainData in _currentStreamData.data){
							window.omnitureData.dl.pageInfo[_mainData]=_currentStreamData.data[_mainData];
							var _detailData = [];
							for(var _additionalData in _currentStreamData.data[_mainData]){
								_detailData.push(_currentStreamData.data[_mainData][_additionalData]);
							}
							window.omnitureData.dl.pageInfo[_mainData]["details"]=_detailData.join("|");
						}
					}
					var linkData = {
						linkName: this.formatLinkData(_currentStreamData).omniture
					};

					if(_currentStreamData.eventId && _currentStreamData.eventId.length>0){
						linkData.linkTrackVars= _currentStreamData.eventId.join(":");
                        linkData.eVar54=window.omnitureData.dl.pageInfo.speedTestResult.details
					}
					window.s.track(this.getEventType(_currentStreamData).omniture, linkData);
				}
			}
		}
		if(this.isCheckingOmniture !== "loaded"){
			localStorage.setItem("analytics-stream", JSON.stringify(this.analyticsStream));
		}
	}

	/* istanbul ignore next */
	formatLinkData(data){

		return {
			omniture:( (window.omnitureData.dl.pageInfo.section)?(window.omnitureData.dl.pageInfo.section+":") : "") +
            data.path.join(":")	+
            ((data.authenticated === "NotNeeded")? "" : (data.authenticated?':authenticated':":unauthenticated")),
			ga: {}
		}
	}

	getEventType(data){
		return {
			omniture: data.eventType,
			ga: "click"
		};
	}
}

const appAnalytics = new AppAnalytics();

window.appAnalytics = appAnalytics;

export default appAnalytics;