import React from "react";
import "./SpeedTroubleshootingEndPageTips.css";
import modem from "../images/singular-smartmodem-blue.svg";
import time from "../images/singular-clock-blue.svg";
import device from "../images/singular-devices-blue.svg";
import BaseRunTimeContainer from "../BaseRunTimeContainer";

export default class SpeedInfoBoxTipsAndTricks extends BaseRunTimeContainer {
    constructor(props) {
        super(props,["Section"]);
    }
    render(){
        const {Section} = this.runTimeComponents;
        return (

            <Section className={"speed-tests"}>
                <h2 className="rt-heading-m rt-margin-top-spacing7x rt-margin-bottom-spacing5x">How to get the most out of speed test</h2>
                <div className="info-container">
                    <div className="speed-info-column rt-margin-right-spacing5x">
                        <img  alt="Time of the day" src={time}/>
                        <h3 className="rt-heading-s rt-margin-bottom-spacing2x rt-margin-top-spacing2x ">Test at different times of the day</h3>
                        <p className={"rt-body-xs-light"}>Speeds can vary throughout the day, based on the number of people on the network at any given time and what they are doing on it.</p>
                        <p className={"rt-body-xs-light rt-margin-top-spacing1x"}>For broadband connections consider checking speeds outside of peak periods from 7pm-11pm in the evening </p>
                    </div>
                    <div className="speed-info-column rt-margin-right-spacing5x">
                        <img  alt="Different devices" src={device}/>
                        <h3 className="rt-heading-s rt-margin-bottom-spacing2x rt-margin-top-spacing2x">Check for other connected devices</h3>
                        <p className={"rt-body-xs-light"}>Check no other people or devices are using the internet in your home or business when you run the test, as internet usage can affect your speed results. This includes software updates.</p>
                    </div>
                    <div className="speed-info-column rt-margin-right-spacing4x">
                        <img  alt="Connect to the modem" src={modem}/>
                        <h3 className="rt-margin-bottom-spacing2x rt-margin-top-spacing2x rt-heading-s">Connect to your modem with a cable</h3>
                        <p className={"rt-body-xs-light"}>If you are using a PC or laptop, you will generally experience more consistent and accurate speed results by connecting with an ethernet cable.
                            If you need to use Wi-Fi you will achieve better results the closer you are to your modem.</p>
                    </div>
                </div>
            </Section>
        );
    }
};
