import React, {Component} from "react";
import { Row } from "react-foundation";
import { Link } from "react-router-dom";
import errorImage from './images/error.svg';
import "./AppPageNotFound.css";
import UsabillaWidget from "./include/UsabillaWidget";
import "../styles/index.css"
import telstraLogo from "../images/telstra-logo.png";

export default class AppPageNotFound extends Component {

    constructor(props) {
        super(props);
        document.title = "speed troubleshooting";
        props.googleAnalytics.initialize();
    }
    render() {
        return (
            <div className="app-error">
                <div className="logo">
                    <img className="image" src={telstraLogo} alt="Telstra Logo"/>
                    <h1>Telstra speed test</h1>
                </div>
                <br/>
                <Row isColumn>
                        <img className='center-image' src={errorImage} alt='Error'/>
                </Row>
                <br/>
                <Row isColumn className="text-big paragraph">
                    We can't find a page with that name.
                </Row>
                <br/>
                <Row isColumn className="message-default">
                    Try using the <b>back</b> button on your browser, or&nbsp;
                    <br/>
                    <Link to="/">
                        go back to the start
                    </Link>.
                    <br/>
                    <br/>
                </Row>
                <Row isColumn className="message-contact">
                    If you would prefer speak to a Telstra
                    consultant, please contact us at&nbsp;
                    <b><Link to="tel:133933" target="_self">13 39 33</Link></b>.
                </Row>
                <div className="feedback-container">
                    <div className="usabilla"><UsabillaWidget context="PAGENOTFOUNDERROR"/></div>
                </div>
            </div>
        );
    }
}