import ReactGA from "react-ga";

export const REACT_APP_GA_TRACKING_ID = 'UA-105300057-1';

const GAKey = () => {
    return window.metadata.app.gaTrackingId || REACT_APP_GA_TRACKING_ID;
};

class GoogleAnalytics {

    static pagePath;

    constructor({reactga = ReactGA,pagePath }) {
        this.reactga = reactga;
        GoogleAnalytics.pagePath = pagePath;
    }

    initialize() {
        this.reactga.initialize(GAKey());
        this.reactga.pageview(GoogleAnalytics.pagePath);
    }

    pageview(path) {
        this.reactga.pageview(path);
    }

    sendEvent(event){
        this.reactga.event({
            category: 'Re-directions',
            action: 'Unauthenticated_Redirection',
            label: 'Unauth_' + event
        });
    }
}

export default GoogleAnalytics
