import 'foundation-sites/dist/css/foundation.min.css'
import React from 'react';
import ReactDOM from 'react-dom';
import App from "./components/App";
import  createBrowserHistory from 'history/createBrowserHistory';
import "./styles/index.css";
import "./selfservice-style/App.css";
import GoogleAnalytics from "./speed-troubleshooting/ga/GoogleAnalytics";
//added for speed troubleshooting
import { Router, Route, Switch } from "react-router";
import AppPageNotFound from "./speed-troubleshooting/AppPageNotFound";

const history = createBrowserHistory();


ReactDOM.render(
    <Router history={history}>
        <div>
            <Switch>
            <Route exact path='/' component={App}/>
            <Route exact path='/support' render={() => (window.location="http://fix.telstra.com/")} googleAnalytics={new GoogleAnalytics({pagePath:"/support"})}/>}/>
            <Route exact path='/support/test' render={() => (window.location="http://speedtest.telstra.com/")} googleAnalytics={new GoogleAnalytics({pagePath:"/support/test"})}/>}/>
            <Route path='/error' component={(props) => <AppPageNotFound googleAnalytics={new GoogleAnalytics({pagePath:"/error"})}/>}/>
            <Route path='*' component={(props) => <AppPageNotFound googleAnalytics={new GoogleAnalytics({pagePath:"/error"})}/>}/>
            </Switch>
        </div>
    </Router>
    , document.getElementById('app'));
