import React, {Component} from 'react';
import runtimeComponent from "./DefaultRunTimeComponent";

export default class BaseRunTimeContainer extends Component{

    constructor(props, runTimeComponentList){
        super(props);
        const _tempComponent = {};
        if(runTimeComponentList && runTimeComponentList.length >> 0){
            runTimeComponentList.forEach(_componentName =>{
                _tempComponent[_componentName] = props[_componentName] || runtimeComponent.defaultComponent;
            });
        }
        this.state = {
            runTimeComponents:_tempComponent
        }
        this.runTimeComponents= this.state.runTimeComponents;
        runtimeComponent.getComponents().then( components =>{
            this.runTimeComponents = components;
            this.setState({runtimeComponent:components});
        })

    }
}
