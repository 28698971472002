import React from "react";
import * as ReactDOM from "react-dom";
import Usabilla from "./Usabilla";

const usabillaWidgetId = (context) => {

    switch(context){
        case "SPEED_TEST" : return window.metadata.app.usabilla.speedTestUsabillaWidgetId || '5b711fc53b4ce82f1b4f40d6';
        default : return window.metadata.app.usabilla.supportTestUsabillaWidgetId || '5a4f77d36478646053639bef';
    }
};

class UsabillaWidget extends React.Component {
    componentDidMount() {
        Usabilla(this.props.context);
        ReactDOM.findDOMNode(this.usabilla).setAttribute('ub-in-page', usabillaWidgetId(this.props.context));
    }

    render() {
        return (
            <div ref={(ref) => this.usabilla = ref}/>
        );
    }
}

export default UsabillaWidget;
